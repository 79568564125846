<template>
  <div>
    <ErrorPage v-if="showError" />
  <div v-else class="flex flex-col gap-4 w-full">
    <c-card class="w-full flex flex-col p-5 pt-11 gap-0">
      <div class="grid grid-cols-3 gap-3">
        <div
          class="flex justify-start items-center gap-3 p-3 border border-cultured rounded-md"
        >
          <c-icon icon-name="icon-clockin" size="s" style="fill: #f15a29" />
          <div class="flex flex-col justify-center items-start gap-0">
            <h3 class="text-xl font-bold text-jet">
              {{ analytics.clockInDays || 0 }}
            </h3>
            <p class="text-xs font-black text-romanSilver uppercase">
              Clocked In (Days)
            </p>
          </div>
        </div>
        <div
          class="flex justify-start items-center gap-3 p-3 border border-cultured rounded-md"
        >
          <c-icon icon-name="icon-clockin" size="s" style="fill: #2176ff" />
          <div class="flex flex-col justify-center items-start gap-0">
            <h3 class="text-xl font-bold text-jet">
              {{ analytics.clockInHours || 0 }}
            </h3>
            <p class="text-xs font-black text-romanSilver uppercase">
              Clocked In (Hours)
            </p>
          </div>
        </div>
        <div
          class="flex justify-start items-center gap-3 p-3 border border-cultured rounded-md"
        >
          <c-icon icon-name="clock-minus" class="text-blueCrayola" size="s" />
          <div class="flex flex-col justify-center items-start gap-0">
            <h3 class="text-xl font-bold text-jet">{{ analytics.absence }}</h3>
            <p class="text-xs font-black text-romanSilver uppercase">
              Absence (Days/Hrs)
            </p>
          </div>
        </div>
      </div>
      <CardFooter
        @actionModal="isOpenFilter = true"
        @sortType="$queryBuilder({ sort: $event }, getOrgClockInOut)"
        @searchResult="$queryBuilder({ search: $event }, getOrgClockInOut)"
      >
        <template v-slot:rightItems>
          <div class="ml-3 flex justify-start items-center gap-0">
            <c-icon icon-name="sort_size" class="cursor-pointer" size="xs" />
            <c-select
              :options="filterByWeekOptions"
              placeholder="Filter By"
              class="select-class"
              @input="$queryBuilder({ period: $event }, getOrgClockInOut)"
            />
          </div>
        </template>
      </CardFooter>
    </c-card>

    <c-table
      :headers="headers"
      :items="clockInOutOptions"
      aria-label="ClockInOut Table"
      :loading="isFetching"
      :has-number="false"
      :page-sync="true"
      :pagination-list="clockInOutMeta"
      @page="$queryBuilder({ page: $event }, getOrgClockInOut)"
      @itemsPerPage="$queryBuilder({ perPage: $event }, getOrgClockInOut)"
      v-if="clockInOutOptions.length || isFetching"
    >
      <template v-slot:item="{ item }">
        <div
          v-if="item.employeeInfo"
          class="flex justify-start items-start gap-2"
        >
          <div>
            <span
              v-if="item.data.employeeInfo.photo"
              class="flex justify-center items-center w-9 h-9"
            >
              <img
                :src="item.data.employeeInfo.photo"
                class="w-9 h-9 rounded"
                alt="profile photo"
              />
            </span>
            <div
              v-else
              class="flex justify-center items-center w-9 h-9 rounded border"
            >
              <span
                class="text-blueCrayola text-center font-semibold text-lg p-2"
              >
                {{
                  $getInitials(
                    `${item.data.employeeInfo.fname} ${item.data.employeeInfo.lname}`
                  )
                }}
              </span>
            </div>
          </div>
          <div class="flex flex-col justify-start gap-0">
            <span class="font-semibold text-darkPurple text-base">
              {{ item.data.employeeInfo.fname }}
              {{ item.data.employeeInfo.lname }}
            </span>
            <span class="font-semibold text-blueCrayola text-xs uppercase">
              {{
                item.data.employeeInfo.userDesignation &&
                item.data.employeeInfo.userDesignation.name
              }}
            </span>
          </div>
        </div>
        <span v-if="item.employeeOffice" class="text-darkPurple text-sm">
          {{ item.data.employeeOffice.name }}
        </span>
        <div v-if="item.clockIn" class="text-darkPurple text-sm">
          <span v-if="!item.data.clockIn">-</span>
          <span v-else>{{
            $DATEFORMAT(new Date(item.data.clockIn), "hh:mm a")
          }}</span>
        </div>
        <div v-if="item.clockOut" class="text-darkPurple text-sm">
          <span v-if="!item.data.clockOut">-</span>
          <span v-else>{{
            $DATEFORMAT(new Date(item.data.clockOut), "hh:mm a")
          }}</span>
        </div>
        <div v-if="item.availability" class="text-darkPurple text-sm">
          <span v-if="!item.data.availability">-</span>
          <span v-else>{{ item.data.availability }}</span>
        </div>
        <div
          v-if="item.status"
          class="rounded-md p-2 text-sm w-28 text-center capitalize"
          :class="{
            'bg-green-100 text-mediumSeaGreen':
              item.data.status === 'clocked in',
            'bg-red-100 text-flame': item.data.status === 'clocked out',
          }"
        >
          {{ item.data.status }}
        </div>
        <c-menu v-if="item.id" left @click.stop="">
          <template v-slot:title>
            <c-icon icon-name="more_icon" size="xs" />
          </template>
          <div class="flex flex-col w-56 h-18 p-2 justify-start items-start">
            <div
              class="w-full"
              @click="
                $router.push({
                  name: 'EmployeeSchedule',
                  params: { id: item.data.id },
                  query: { currentTab: $route.query.currentTab },
                })
              "
            >
              <div class="hover:bg-ghostWhite w-full rounded-md">
                <div
                  class="flex justify-start items-center p-2 gap-2 cursor-pointer"
                >
                  <c-icon
                    icon-name="icon-eye"
                    class-name="text-blueCrayola"
                    size="xs"
                  />
                  <span class="text-darkPurple text-sm leading-5 font-normal"
                    >View Work Schedule</span
                  >
                </div>
              </div>
            </div>
          </div>
        </c-menu>
      </template>
    </c-table>

    <div v-else class="flex flex-col justify-center items-center gap-0 px-10">
      <c-icon
        icon-name="paygrade_Illustration"
        size=""
        class-name="w-56 h-56"
      />
      <p class="font-normal text-base leading-5 text-jet text-center w-1/2">
        Time tracking locations can be found here. This is empty now, but you
        can start adding time tracking locations to populate this area.
      </p>
    </div>

    <FilterTable
      v-if="isOpenFilter"
      :filter-data="filterOptions"
      @close="isOpenFilter = false"
      @applyFilter="onSubmitFilter($event)"
    />
  </div>
  </div>
</template>

<script>
import CTable from "@scelloo/cloudenly-ui/src/components/table";
import CCard from "@scelloo/cloudenly-ui/src/components/card";
import CMenu from "@scelloo/cloudenly-ui/src/components/Menu";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import CIcon from "@/components/Icon";
import CardFooter from "@/components/CardFooter";
import FilterTable from "@/components/FilterTable";
import ErrorPage from "@/modules/Admin/error403";

export default {
  components: {
    CMenu,
    CCard,
    ErrorPage,
    CIcon,
    CSelect,
    CardFooter,
    CTable,
    FilterTable,
  },
  computed: {
    radiusInMeters() {
      return Array.from({ length: 50 }, (_, i) => i + 1);
    },
    filterOptions() {
      return [
        {
          header: "Location",
          value: "location",
          optionsBox: this.locationOptions,
        },
        { header: "Function", value: "function", optionsBox: [] },
        { header: "Designation", value: "designation", optionsBox: [] },
        { header: "Job Level", value: "jobLevel", optionsBox: [] },
        { header: "Employment Type", value: "employmentType", optionsBox: [] },
        { header: "Status", value: "status", optionsBox: this.statusOptions },
      ];
    },
  },
  data() {
    return {
      isOpenFilter: false,
      isFetching: false,
      showError: false,
      isSubmitting: false,
      analytics: {},
      clockInOutOptions: [],
      clockInOutMeta: {},
      locationOptions: [],
      selectedLocation: {},
      latitude: null,
      longitude: null,

      headers: [
        { title: "Employee Name", value: "employeeInfo" },
        { title: "Location", value: "employeeOffice" },
        { title: "Clock In", value: "clockIn" },
        { title: "Clock Out", value: "clockOut" },
        { title: "Availability", value: "availability" },
        { title: "Status", value: "status" },
        { title: "", value: "id", image: true },
      ],
      statusOptions: [
        { name: "Active", id: "active" },
        { name: "Inactive", id: "inactive" },
      ],
      filterByWeekOptions: [
        { name: "All", id: "all" },
        { name: "Today", id: "today" },
        { name: "This Week", id: "this_week" },
        { name: "This Month", id: "this_month" },
        { name: "Custom", id: "custom" },
      ],

      payload: {
        locationIds: [],
        address: null,
        clockInRadius: null,
        clockOutRadius: null,
      },
    };
  },
  methods: {
    onSubmitFilter(options) {
      this.$queryBuilder(options, this.getOrgClockInOut);
      this.isOpenFilter = false;
    },
    async getOrgClockInOut(params) {
      this.isFetching = true;
      await this.$_getOrgClockInOut(params).then(({ data }) => {
        // console.log('CLOCK', data)
        this.analytics = data.analytics;
        this.clockInOutOptions = data.employees.map((item) => ({
          ...item,
          employeeOffice: item.employeeInfo.employeeOffice,
        }));
        this.clockInOutMeta = data.meta;
        this.isFetching = false;
      });
    },

    async bootstrapModule() {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
        });
      }

      await this.getOrgClockInOut();
    },
  },
  async created() {
    try {
      await this.$handlePrivilege("timeAttendanceClockInClockOut", "viewRegister");
      this.showError = false;
      this.bootstrapModule();
    } catch (error) {
      this.showError = true;
    }
  },
};
</script>

<style scoped>
::v-deep .select-class span select {
  margin-top: 3px !important;
  height: 42px !important;
  border: 0 !important;
}
.button-style {
  font-weight: 600 !important;
  font-size: 16px !important;
  font-size: 16px !important;
  line-height: 120% !important;
  padding: 10px 50px !important;
}
.c-button {
  width: 100% !important;
  padding: 10px 40px !important;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
